<template>
  <div>
    <h1 :class="$style.pageTitle">お知らせ</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-else class="p-2">
              <ul :class="$style.post_meta_top">
                <li :class="$style.category">{{ notification.category }}</li>
                <li :class="$style.date">{{ notification.date | formatDate }}</li>
              </ul>
              <h2 :class="$style.post_title">{{ notification.title }}</h2>
              <div :class="$style.page_main_content">
                <div :class="$style.post_content">{{ notification.description }}</div>
                <div :class="$style.bottom_button">
                  <router-link :to="{ name: 'notifications' }">
                    <a-button>戻る</a-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
@import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import { objectData } from '@/services/object'

export default {
  name: 'FormNotification',
  data() {
    return {
      loading: true,
      backPath: '/notifications/list',
      wrapperCol: { span: 14 },
      notification: Object.assign({}, objectData.notification),
      form: {
        title: '',
        category: '',
        date: moment(),
        description: '',
        is_public: 0,
      },
    }
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'notifications/show/' + this.$route.params.id)
      .then(response => {
        this.notification = response
        this.loading = false
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': お知らせデータの取得に失敗しました。',
        })
        this.$router.push(this.backPath)
      })
  },
  filters: {
    formatDate: function (date) {
      if (date) {
        return moment(date).format('YYYY.MM.DD')
      } else {
        return '-'
      }
    },
  },
}
</script>
