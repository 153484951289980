var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("お知らせ")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _c("div", { staticClass: "p-2" }, [
                  _c("ul", { class: _vm.$style.post_meta_top }, [
                    _c("li", { class: _vm.$style.category }, [
                      _vm._v(_vm._s(_vm.notification.category))
                    ]),
                    _c("li", { class: _vm.$style.date }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(_vm.notification.date))
                      )
                    ])
                  ]),
                  _c("h2", { class: _vm.$style.post_title }, [
                    _vm._v(_vm._s(_vm.notification.title))
                  ]),
                  _c("div", { class: _vm.$style.page_main_content }, [
                    _c("div", { class: _vm.$style.post_content }, [
                      _vm._v(_vm._s(_vm.notification.description))
                    ]),
                    _c(
                      "div",
                      { class: _vm.$style.bottom_button },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "notifications" } } },
                          [_c("a-button", [_vm._v("戻る")])],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }